import axios from 'axios';
import {useEffect, useState} from "react";

// axios.defaults.proxy.host = 'http://localhost';
axios.defaults.baseURL = process.env.REACT_APP_SERVER_ROOT;
// axios.defaults.baseURL = 'https://docube.finexio.biz';

axios.defaults.withCredentials = true;
// Sends POST request using the given request and urlPath.
export async function sendRequest(urlPath, request, params = {}) {
  try {
    const response = await axios.post(urlPath, request, {params});
    return handleResponse(response);
  } catch (error) {
    throw error;
  }
}

// Returns response if the status code is 200, otherwise throw error.
export async function handleResponse(response) {
  if (response.status === 200) {
    // Successful response, simply return
    return response;
  }

  // Unknown error occurred
  throw new Error(
    'Unknown API response error, response did not return with a status code of 200.'
  );
}

// Extracts and returns the page data to display for the given error.
export function handleError(error) {
  const errorMessage = error.response.data;
  let errorPageText;
  if (errorMessage) {
    errorPageText = {
      title: errorMessage.title,
      description: errorMessage.description,
    };
  }
  return errorPageText;
}

export function useAxios(url, body, shouldCall = true) {
  const [result, setResult] = useState({});

  useEffect(() => {
    async function fetchData() {
      if (shouldCall) {
        setResult({data: null, loading: true, error: false});
        try {
          const response = await axios.post(url, body);
          if (response.status >= 200 && response.status < 300) {
            setResult({data: response.data, loading: false, error: false});
          } else {
            setResult({data: null, loading: false, error: true});
          }
        } catch (error) {
          setResult({data: null, loading: false, error: true});
        }
      } else {
        setResult({data: null, loading: false, error: true});
      }
    }
    fetchData();
  }, []);
  return result;
}
